<template>
  <div class="wet-conditions">
    <div class="d-flex">
      <div
        v-for="(cond, index) in conditions"
        :key="index"
        :style="{
          'flex': '0 0 auto',
          'width': `${100 / (conditions.length || 1)}%`
        }"
      >
        <div class="wet-conditions-icon">
          <component
            :is="cond.iconComponent"
            :type="cond.icon"
            :intensity="cond.intensity || 'medium'"
            class="w-100"
          />
        </div>
        <div class="wet-conditions-title empty-ph">{{cond.title}}</div>
        <div>
          <div class="wet-conditions-link">
            <router-link
              v-if="cond.link.to"
              :id="`wet-conditions-link-${cond.link.name}`"
              :to="cond.link.to"
              event=""
              @click.native="$emit('select', cond.link.to)"
              :title="cond.link.title"
              class="empty-ph"
              exact
            >{{cond.link.title}}</router-link>
            <span v-else>{{ cond.link.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @conditions = [
 *   {
 *     icon: String,
 *     iconComponent: String,
 *     title: String,
 *     link: {
 *       title: String,
 *       to: [String, Object],
 *     },
 *   },
 *  ...
 * ];
 */
export default {
  props: {
    conditions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    weatherIcon: () => import(/* webpackChunkName: "weatherIcon" */ '@/components/weatherIcon.vue'),
    wetIcon: () => import(/* webpackChunkName: "wetIcon" */ '@/components/wetIcon.vue'),
  },
};
</script>
<style lang="scss">
  .wet-conditions {
    --wet-conditions-icon-size: 1.25rem;
    word-break: break-word;
    font-size: .8rem;
    font-weight: 700;
  }
  .wet-conditions-icon {
    width: var(--wet-conditions-icon-size);
    float: left;
    color: var(--primary);
  }
  .wet-conditions-title {
    padding-left: calc(var(--wet-conditions-icon-size) * 1.25);
  }
  .wet-conditions-link {
    padding-left: calc(var(--wet-conditions-icon-size) * 1.25);
    color: var(--secondary);

    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
</style>
